import React, {useEffect} from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import onPageSeo from "../images/on-page-seo.svg"
import technicalSeo from "../images/technical-seo.svg"

const Seo = () => {

    return (
        <Layout>
        <main id='main' style={{ backgroundColor: "rgb(36, 41, 67)" }}>
          <h2 className="text-center text-4xl my-16">Search Engine Optimization</h2>
          <div className="grid grid-cols-3 grid-auto-rows px-16 md:px-32 lg:px-64">
            <div className="col-start-1 col-span-3 grid-row-1 md:col-span-2 row-start-1 my-16 pt-5">
              <h2 className="text-2xl font-bold pl-5">On Page SEO</h2>
              <p className="text-xl p-5">
                On page SEO refers to the contents of your webpage. These are factors that directly influence search
                engines ability to understand the content and see that it is a valuble resource people want to find. Keyword 
                research, high quality content and keyword optimization are just a few of the strategies.
              </p>
            </div>
  
            <div className="col-start-1 col-span-3 md:col-span-1 row-start-2 md:row-start-1 my-16">
              <img
                src={onPageSeo}
                style={{
                  filter:
                    "invert(99%) sepia(68%) saturate(247%) hue-rotate(347deg) brightness(120%) contrast(100%)",
                }}
                className="opacity-75"
              />
            </div>
  
            <div className="col-start-1 col-span-3 md:col-start-2 row-start-3 md:row-start-2 my-16">
              <h2 className="text-2xl font-bold pl-5">Technical SEO</h2>
              <p className="text-xl p-5">
                Technical SEO refers to techniques for improving your website's front end and backend structure to optimize for
                speed and mobile friendliness, crawlability, security and more. Each of these categories improve your pages
                overall SEO rating.

              </p>
            </div>
            <div className="col-start-1 col-span-3 md:col-span-1 row-start-4 md:row-start-2 my-16">
              <img
                src={technicalSeo}
                style={{
                  filter:
                    "invert(99%) sepia(68%) saturate(247%) hue-rotate(347deg) brightness(120%) contrast(100%)",
                }}
                className="opacity-75"
              />
            </div>
            <div className='col-start-1 col-span-3 md:col-span-2 my-16'>
            <h2 className="text-2xl">More SEO methods employed by us</h2>
              <p>
                
              </p>
              <div className="w-full flex ">
                <ol className="list-disc list-inside">
                  <li>Off-Site SEO</li>
  
                </ol>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    )
}

export default Seo
